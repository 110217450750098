import {
  Box,
  Flex,
  IconButton,
  Text,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { LuSettings2 } from "react-icons/lu";
import { useHistory } from "react-router-dom";
import { tw } from "twind";
import { ProfileStatusEnum } from "../enum/profile.enum";
import { IProfile } from "../interface/profile.interface";

function ProfileCard({
  firstName,
  lastName,
  status,
  publicIdentifier,
  setting,
  _id,
}: IProfile) {
  const cardHoverShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  const cardShadow = useColorModeValue(
    "0px 6px 15px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const bg = useColorModeValue("white", "navy.700");
  const iconBgColor = useColorModeValue("brand.500", "blue");
  const iconBgHoverColor = useColorModeValue("brand.300", "blue");
  const history = useHistory();

  const getProfileStatus = () => {
    if (!setting || (setting && !Object.keys(setting).length))
      return "Not Activated";
    else if (status === ProfileStatusEnum.ACTION_REQUIRED)
      return "Action Required";
    else if (status === ProfileStatusEnum.OK) return "In Progress";
    return "No Status";
  };

  const getProfileStatusTextColor = () => {
    if (!setting) return "red.400";
    else if (status === ProfileStatusEnum.ACTION_REQUIRED) return "red.400";
    else if (status === ProfileStatusEnum.OK) return "green.400";
    return "red.400";
  };

  return (
    <Card
      bg={bg}
      p={6}
      minW="250px"
      maxW="300px"
      boxShadow={cardShadow}
      className={tw(`transition-all ease-in-out hover:cursor-pointer`)}
      _hover={{ boxShadow: cardHoverShadow }}
      onClick={() => history.push(`/profile/${_id}/setting`)}
    >
      <Box w="full">
        <Box mt={{ base: "10px", md: "0" }}>
          <Text color={textColorPrimary} fontWeight="500" fontSize="md">
            {`${firstName} ${lastName}`}
          </Text>
          <Text
            fontWeight="500"
            color={textColorSecondary}
            fontSize="sm"
            me="4px"
            mb="4px"
          >
            {`@${publicIdentifier}`}
          </Text>
          <Text
            fontWeight="500"
            color={getProfileStatusTextColor()}
            fontSize="sm"
            me="4px"
          >
            {getProfileStatus()}
          </Text>
          <Flex justifyContent="flex-end" alignItems="center" w="full">
            <Tooltip label="Go to keyword settings">
              <IconButton
                aria-label="Settings"
                icon={<LuSettings2 />}
                size="sm"
                bgColor={iconBgColor}
                color="white"
                _hover={{ backgroundColor: iconBgHoverColor }}
              />
            </Tooltip>
          </Flex>
        </Box>
      </Box>
    </Card>
  );
}

export default ProfileCard;
