import { ArrowRightIcon, ChevronRightIcon } from "@chakra-ui/icons";
import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Tag,
  TagCloseButton,
  TagLabel,
  useColorModeValue,
} from "@chakra-ui/react";
import { omit } from "@chakra-ui/utils";
import Card from "components/card/Card";
import { FocusEvent, KeyboardEvent } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import {
  IScrapeSettingForm,
  ISetting,
} from "../../interface/setting.interface";
import { useSettingFormStore } from "../../store/useSettingForm.store";
import { TbExternalLink, TbInfoCircle } from "react-icons/tb";
import { tw } from "twind";
import { buildSearchUrl } from "../../utils/linkedin.util";
import { planSetting } from "../../config/plan-setting.config";
import { useGetUserQuery } from "views/auth/query/user.query";

function ScrapeSetting({
  next,
  setting,
}: {
  next: () => void;
  setting: ISetting["scrapeSetting"];
}) {
  const { data: user } = useGetUserQuery();
  const userPlan = user?.subscribedProduct?.name?.toLowerCase() as
    | "starter"
    | "pro"
    | "premium";
  const { scrapeSetting, setScrapeSetting } = useSettingFormStore();
  const defaultValues = {
    skipHiringPosts: true,
    skipJobUpdatePosts: true,
    skipArticlePosts: true,
  };
  const formRegistry = useForm<IScrapeSettingForm>({
    defaultValues: scrapeSetting ?? setting ?? defaultValues,
  });
  const iconBgColor = useColorModeValue("brand.500", "blue");
  const maxNumberOfCommentsPerData =
    planSetting["numberOfPostsToScrapePerDay"]?.[userPlan] ?? 20;

  const {
    handleSubmit,
    formState: { errors },
    register,
    control,
    setValue,
    watch,
  } = formRegistry;

  const { append, remove } = useFieldArray({
    control,
    name: "keywordsToTarget" as never,
    rules: {
      required: "Please enter atleast 1 keyword",
    },
  });

  const onSubmit = (data: IScrapeSettingForm) => {
    const formData = omit(data, ["keyword"]);
    setScrapeSetting(formData);
    next();
  };

  const keywordRegex = /^[a-zA-Z0-9\s.,'-]+$/;

  const appendKeyword = (value: string) => {
    if (keywordRegex.test(value)) {
      append(value);
      setValue("keyword", "");
    }
  };

  const onKeywordsFieldBlur = (
    event: FocusEvent<HTMLInputElement, Element>
  ) => {
    const value = event.target.value;
    appendKeyword(value);
  };

  const onKeywordsFieldKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value;
    if (event.key === "Enter") appendKeyword(value);
  };

  const onRemoveKeyword = (index: number) => {
    remove(index);
  };

  const keywords = watch("keywordsToTarget") ?? [];
  const tempKeyword = watch("keyword");

  const liSearchUrl = buildSearchUrl(keywords);

  return (
    <Card mt={8} p={5} pb={8} gap={5}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl
          mt={4}
          isRequired
          isInvalid={Boolean(errors?.keywordsToTarget)}
        >
          <FormLabel>Target Keywords</FormLabel>
          <Flex gap={1} mb={keywords?.length ? 3 : 0}>
            {keywords?.map((field, index) => (
              <Tag
                size={"md"}
                borderRadius="full"
                variant="solid"
                colorScheme="blue"
                key={index}
              >
                <TagLabel>{field}</TagLabel>
                <TagCloseButton onClick={() => onRemoveKeyword(index)} />
              </Tag>
            ))}
          </Flex>

          <Controller
            control={formRegistry.control}
            name="keyword"
            render={({ field: { onChange, value } }) => (
              <InputGroup size="md">
                <Input
                  placeholder="e.g. marketing"
                  fontSize="sm"
                  fontWeight="500"
                  onChange={onChange}
                  value={value}
                  onBlur={onKeywordsFieldBlur}
                  onKeyDown={onKeywordsFieldKeyDown}
                  required={false}
                />
                <InputRightElement>
                  <IconButton
                    aria-label="Insert"
                    icon={<ChevronRightIcon />}
                    size="sm"
                    bgColor={iconBgColor}
                    color="white"
                    onClick={() => appendKeyword(tempKeyword)}
                  />
                </InputRightElement>
              </InputGroup>
            )}
          />
          <FormHelperText>
            Click on the right icon to enter multiple keywords
          </FormHelperText>
          {Boolean(keywords?.length) && (
            <FormHelperText className={tw(`flex items-center gap-1`)}>
              <TbInfoCircle />
              <a href={liSearchUrl} target="_blank">
                Before saving your keywords, check if any LinkedIn posts contain
                these keywords
              </a>
              <TbExternalLink />
            </FormHelperText>
          )}

          {Boolean(errors?.keywordsToTarget) && (
            <FormErrorMessage>Please enter at least 1 keyword</FormErrorMessage>
          )}
        </FormControl>

        <FormControl
          mt={5}
          isRequired
          isInvalid={Boolean(errors?.numberOfPostsToScrapePerDay?.message)}
        >
          <FormLabel>Number of posts to scrape per day</FormLabel>
          <Input
            placeholder="50"
            fontSize="sm"
            fontWeight="500"
            type="number"
            max={maxNumberOfCommentsPerData}
            {...register("numberOfPostsToScrapePerDay")}
          />
          <FormHelperText textAlign="start" fontSize="xs" ml={1}>
            Max. {maxNumberOfCommentsPerData} posts per day
          </FormHelperText>
          <FormErrorMessage>
            {errors?.numberOfPostsToScrapePerDay?.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl>
          <FormLabel mt={5}>Skip commenting on</FormLabel>
          <Stack
            rowGap={2}
            columnGap={5}
            direction={["column", "row"]}
            flexWrap="wrap"
          >
            <Checkbox colorScheme="blue" {...register("skipHiringPosts")}>
              Hiring Posts
            </Checkbox>
            <Checkbox colorScheme="blue" {...register("skipJobUpdatePosts")}>
              Job Update Posts
            </Checkbox>
            <Checkbox colorScheme="blue" {...register("skipCompanyPosts")}>
              Company Posts
            </Checkbox>
            <Checkbox colorScheme="blue" {...register("skipArticlePosts")}>
              Article Posts
            </Checkbox>
          </Stack>

          <Flex mt={8} justify="flex-end">
            <Button
              variant="brand"
              fontWeight="500"
              fontSize="sm"
              type="submit"
              minW="120px"
              rightIcon={<ArrowRightIcon />}
            >
              Next
            </Button>
          </Flex>
        </FormControl>
      </form>
    </Card>
  );
}

export default ScrapeSetting;
