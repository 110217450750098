export const envConfig = {
  environment: (process.env.REACT_APP_ENV as string) || "local",
  apiUrl: (process.env.REACT_APP_API_URL as string) || "http://localhost:8000",
  appUrl: (process.env.REACT_APP_URL as string) || "http://localhost:3000",
  openReplayProjectId:
    (process.env.REACT_APP_OPEN_REPLAY_ID as string) || "71jGSol3gtbBFlTgM5qL",
  appziToken: (process.env.REACT_APP_APPZI_TOKEN as string) || "G4U7N",
  extensionUrl:
    (process.env.REACT_APP_EXTENSION_URL as string) ||
    "https://chromewebstore.google.com/detail/commentify-automate-linke/efmnkiklpnaekhleodlncoembopfmjca",
  crispApiKey:
    (process.env.REACT_APP_CRISP_API_KEY as string) ||
    "517b7dc4-a006-4383-afcc-4c3e3416cd05",
};
